<template>
  <app-layout>
    <div class="flex items-center justify-center">
      <div
        class="w-11/12 md:w-8/12 xl:w-7/12 mx-auto md:mx-0 rounded-lg bg-white bg-opacity-80 p-3 mb-5 text-justify"
      >
        <h1 class="text-4xl leading-9 bold text-green-1003 mb-6">SOBRE</h1>
        <p class="mb-6 text-md leading-6 md:text-lg xl:text-2xl">
          Colheita de Talentos é
          <span class="bold"
            >uma iniciativa do Senar Mato Grosso e Sindicatos Rurais</span
          >
          para o produtor rural. O <span class="bold">Senar MT</span> tem mais
          de 370 cursos que atendem as principais cadeias produtivas no estado.
          Para que capacitação e qualificação cheguem ao campo contamos com
          <span class="bold">9 Centros de Treinamento</span> (Cts),
          <span class="bold">47 Núcleos Avançados de Capacitação</span> (NACs),
          <span class="bold">10 Escritórios Regionais</span> e em breve teremos
          um Centro de Excelência em Integração Lavoura-Pecuária-Floresta. Além
          de tudo isso, ainda oferecemos Assistência Técnica e Gerencial (ATeG)
          para pequenos e médios produtores.
        </p>
        <p class="mb-6 text-md leading-6 md:text-lg xl:text-2xl">
          Para chegar nos quatro cantos do estado e fazer esse trabalho tão bem
          <span class="bold">contamos com o apoio dos 93 Sindicatos Rurais</span
          >. <span class="bold">O Sindicato é o elo com o produtor rural</span>,
          sempre pronto para ouvir as reivindicações, divulgar as conquistas que
          construímos juntos e as ações de todas as entidades do
          <span class="bold">Sistema Famato</span>.
        </p>
        <p class="text-md leading-6 md:text-lg xl:text-2xl">
          Com tanta estrutura e qualificação que é levada aos trabalhadores
          rurais e muita mão de obra de qualidade que conseguimos preparar para
          o mercado de trabalho, o Colheita de Talentos é
          <span class="bold"
            >uma ferramenta para aproximar o Produtor Rural dessa mão de
            obra</span
          >, mostrando as melhores opções que já foram treinadas e qualificadas
          pelo <span class="bold">Senar-MT</span>.
        </p>
      </div>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from '../layouts/AppLayout';

export default {
  name: 'About',

  title() {
    return `${process.env.VUE_APP_NAME} | Sobre`;
  },

  components: {
    AppLayout
  }
};
</script>
